export const LogoLiip = () => {
  return (
    <svg
      viewBox="0 0 199 72"
      width="83"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      className="fill-current hover:text-highlight transition"
    >
      <path d="m125.352941.70588235 14.588235 7.05882353-32.705882 63.29411762-14.5882352-7.0588235zm-37.6470586 0 14.3529416 7.05882353-32.7058828 63.29411762-14.5882353-7.0588235zm78.8235296 45.17647055v23.0588236h-16.235294v-64.9411765h26.117647c14.588235 0 22.588235 10.3529412 22.588235 20.9411765 0 10.8235294-7.764706 20.9411764-22.588235 20.9411764zm0-27.5294117v13.1764706h9.17647c4.470589 0 7.058824-3.2941177 7.058824-6.5882353 0-3.2941177-2.588235-6.5882353-7.058824-6.5882353zm-166.11764729-14.3529412h16.23529409v50.8235294h27.7647059v14.1176471h-43.99999999z"></path>
    </svg>
  )
}
